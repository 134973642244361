<template>
  <b-modal 
    :id="'makePageModal' + item.등록번호" 
    size="xl" 
    title="AI이미지번역" 
    hide-header
    ok-title="닫기" 
    ok-only 
    @ok="saveItem(item)" 
    content-class="dark-mode"
    dialog-class="full-screen-modal"
  >
    <div class="container-fluid px-5">
      <div class="mt-2 mb-4 d-flex justify-content-between">
        <div>
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="editModeRadio" name="editMode" class="custom-control-input" v-model="editMode" value="edit" @change="changeMode(item)">
            <label class="custom-control-label" for="editModeRadio">편집모드</label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="downloadModeRadio" name="editMode" class="custom-control-input" v-model="editMode" value="download" @change="changeMode(item)">
            <label class="custom-control-label" for="downloadModeRadio">다운로드모드</label>
          </div>
        </div>
        <div>
          <b-button @click="aiImageTranslateAll(item)" variant="outline-primary" class="text-center">전체 AI이미지번역</b-button>
        </div>
      </div>
      <div class="row border-top mt-2">
        <div class="col-3">
          <div class="mb-3">
            <h6>이미지 유형</h6>
            <div class="form-check">
              <input class="form-check-input" type="radio" v-model="currentImageType" id="thumbnailRadio" value="thumbnailImages" name="imageType" @change="changeImageType">
              <label class="form-check-label" for="thumbnailRadio">썸네일 이미지</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" v-model="currentImageType" id="optionRadio" value="optionImages" name="imageType" @change="changeImageType">
              <label class="form-check-label" for="optionRadio">옵션 이미지</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" v-model="currentImageType" id="detailRadio" value="detailImages" name="imageType" @change="changeImageType">
              <label class="form-check-label" for="detailRadio">상세 이미지</label>
            </div>
          </div>
          <div class="image-grid">
            <div v-for="(img, i) in currentImages" :key="'current-'+i" class="image-item">
              <img :src="img.translatedImg || img.img" 
                   :class="{ 'selected': selectedImageIndex === i, 'translating': isImageTranslating(i) }"
                   @click="selectImage(img, i)">
              <div v-if="isImageTranslating(i)" class="overlay">
                <div class="spinner"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="row">
            <div class="col-9 edit-area" ref="editArea">
              <div v-if="currentImageType !== 'detailImages' && selectedImageIndex !== null" class="selected-image-container">
                <img :src="currentImages[selectedImageIndex].translatedImg || currentImages[selectedImageIndex].img" 
                     :class="{ 'translating': isImageTranslating(selectedImageIndex) }"
                     class="selected-image">
              </div>
              <div v-else-if="currentImageType === 'detailImages'" class="detail-images-container">
                <div v-for="(img, i) in currentImages" :key="'detail-'+i" class="detail-image-item" :class="{ 'current-image': selectedImageIndex === i }">
                  <img :src="img.translatedImg || img.img" 
                       :class="{ 'translating': isImageTranslating(i) }"
                       class="detail-image">
                </div>
              </div>
              <div v-else class="no-image-selected">
                <p>이미지를 선택해주세요.</p>
              </div>
            </div>
            <div class="col-3">
              <div class="toolbar">
                <h5>도구모음</h5>
                <button class="btn btn-primary mb-2 w-100" @click="aiImageTranslate(item)" :disabled="!isImageSelected">이미지 번역</button>
                <button class="btn btn-success mb-2 w-100" @click="imageEdit(item)" :disabled="!isImageSelected">이미지 편집</button>
                <button class="btn btn-danger mb-2 w-100" @click="deleteCurrentImage(item)" :disabled="!isImageSelected">이미지 삭제</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="showImageEditor" size="xl" title="이미지 에디터" @shown="initializeImageEditor" @hidden="closeImageEditor" hide-footer>
      <iframe
        id="xiangji-image-editor"
        title="쿠플러스 AI이미지에디터"
        style="width: 100%; height: 700px;"
        src="https://www.xiangjifanyi.com/image-editor/#/?lang=KOR&logo=hidden"
        frameborder="0"
      ></iframe>
    </b-modal>
  </b-modal>
</template>

<script>
import axios from 'axios';
export default {
  props: ['item'],
  data() {
    return {
      editMode: 'edit',
      currentImageType: 'thumbnailImages',
      selectedImageIndex: null,
      showImageEditor: false,
      isTranslating: false,
      translatingImages: {},
      editorRequestId: null,
      editorImgUrl: null,
      geminiAPIKey:"",
    }
  },
  computed: {
    currentImages() {
      return this.item[this.currentImageType] || [];
    },
    isImageSelected() {
      return this.selectedImageIndex !== null;
    }
  },
  mounted() {
    this.currentImageType = 'thumbnailImages';
    this.selectedImageIndex = 0;
    this.geminiAPIKey = this.$store.state.settings[0].geminiAPIKey;
    this.$nextTick(() => {
      if (this.currentImageType === 'detailImages') {
        this.$refs.editArea.addEventListener('scroll', this.handleScroll);
      }
    });
  },
  beforeDestroy() {
    if (this.$refs.editArea) {
      this.$refs.editArea.removeEventListener('scroll', this.handleScroll);
    }
  },
  methods: {
    changeMode(item) {
      if (this.editMode === 'download') {
        this.toggleAllImages(item, true);
      }
    },
    toggleAllImages(item, value) {
      ['thumbnailImages', 'optionImages', 'detailImages'].forEach(type => {
        if (item[type]) {
          item[type].forEach(img => {
            img[`selected${type.charAt(0).toUpperCase() + type.slice(1, -1)}s`] = value;
          });
        }
      });
    },
    selectImage(img, index) {
      this.selectedImageIndex = index;
    },
    isImageTranslating(index) {
      return this.translatingImages[index] || false;
    },
    changeImageType() {
      this.selectedImageIndex = 0;
      this.$nextTick(() => {
        if (this.currentImageType === 'detailImages') {
          this.$refs.editArea.addEventListener('scroll', this.handleScroll);
        } else {
          this.$refs.editArea.removeEventListener('scroll', this.handleScroll);
        }
      });
    },
    handleScroll() {
      const container = this.$refs.editArea;
      const items = container.querySelectorAll('.detail-image-item');
      const containerTop = container.scrollTop;
      const containerHeight = container.clientHeight;

      let closestItem = null;
      let closestDistance = Infinity;

      items.forEach((item, index) => {
        const itemTop = item.offsetTop;
        const itemBottom = itemTop + item.clientHeight;
        const itemMiddle = (itemTop + itemBottom) / 2;

        const distance = Math.abs(containerTop + containerHeight / 2 - itemMiddle);

        if (distance < closestDistance) {
          closestDistance = distance;
          closestItem = item;
          this.selectedImageIndex = index;
        }
      });

      if (closestItem) {
        items.forEach(item => item.classList.remove('current-image'));
        closestItem.classList.add('current-image');
      }
    },
      async aiImageTranslateAll(item) {
        if (!confirm('전체 이미지를 번역하시겠습니까?')) {
            return;
        }
        this.isTranslating = true;

        const allImages = [
            ...item.thumbnailImages.map((_, index) => ({ type: 'thumbnailImages', index })),
            ...item.optionImages.map((_, index) => ({ type: 'optionImages', index })),
            ...item.detailImages.map((_, index) => ({ type: 'detailImages', index }))
        ];

        for (const { type, index } of allImages) {
            try {
            this.currentImageType = type;
            this.selectedImageIndex = index;
            await this.aiImageTranslate();
            await new Promise(resolve => setTimeout(resolve, 1000));  // 1초 대기
            } catch (error) {
            console.error(`Error translating ${type} image at index ${index}:`, error);
            }
        }

        this.isTranslating = false;
        alert('전체 이미지 번역이 완료되었습니다.');
        },
        async aiImageTranslate() {
          if (!this.isImageSelected || this.isTranslating) return;            
          this.isTranslating = true;
          this.$set(this.translatingImages, this.selectedImageIndex, true);
          try {
            const img = this.currentImages[this.selectedImageIndex];
            const res = await this.getImageTranslate([{imgUrl: img.img}]);
            const transInfo = res.data.result[0];
            img.transInfo = transInfo;
            img.translatedImg = transInfo.Data.Url;
            const result = await this.getImageTranslateBatchQuery([transInfo.RequestId]);
            const ocrInfo = result.Data.Content[transInfo.RequestId];
            if (ocrInfo.Orc) {
              const aiResult = await this.getAITranslate(img.img, ocrInfo.Orc.map(e => e.source));
              ocrInfo.AITranslate = aiResult;
              const ocrData = ocrInfo.Orc.map(e => {
                const obj = this.deepClone(e);
                delete obj.id;
                obj.target = aiResult.find(f => f.ch === e.source)?.ko[0] || e.target;
                return obj;
              });
              
              if (aiResult && aiResult.length > 0) {
                const transInfoOCR = await this.getImageOCR({
                  preRequestId: transInfo.RequestId,
                  url: transInfo.Data.Url,
                  rmUrl: transInfo.Data.RmUrl,
                  ocrData: ocrData,
                });
                Object.assign(ocrInfo, {
                  Url: transInfoOCR.data.result.Data.Url,
                  RmUrl: transInfoOCR.data.result.Data.RmUrl,
                  RequestId: transInfoOCR.data.result.RequestId
                });
              }
            }
            img.ocrInfo = this.deepClone(ocrInfo);
          } catch (error) {
              console.error('Translation error:', error);
          } finally {
              this.$set(this.translatingImages, this.selectedImageIndex, false);
              this.isTranslating = false;
          }
        },
      async imageEdit() {
        if (!this.isImageSelected || this.isTranslating) return;
        
        this.isTranslating = true;
        const img = this.currentImages[this.selectedImageIndex];
        const requestId = img.transInfo ? img.transInfo.RequestId : "";
        const imgUrl = img.translatedImg || img.img;

        this.showImageEditor = true;
        this.editorRequestId = requestId;
        this.editorImgUrl = imgUrl;
        },
      deleteCurrentImage(item) {
        if (confirm('이 이미지를 삭제하시겠습니까?')) {
          item[this.currentImageType].splice(this.selectedImageIndex, 1);
          this.selectedImageIndex = null;
        }
      },
      initializeImageEditor() {
        const iframe = document.getElementById('xiangji-image-editor');
        if (iframe) {
          iframe.onload = () => {
            iframe.contentWindow.postMessage(
              {
                name: "XJ_IMAGE_EDITOR_REQUESTIDS",
                requestIds: [this.editorRequestId]
              },
              'https://www.xiangjifanyi.com'
            );
          };
        } else {
          console.error('Image editor iframe not found');
        }
      },
      closeImageEditor() {
        this.showImageEditor = false;
        this.isTranslating = false;
      },
      async saveItem(item) {
        try {
          const res = await axios.post('/api/aiPage/saveItems', [item]);
          if (res.data.res === 'success') {
            alert('저장완료');
          } else {
            alert('저장실패 : ' + res.data.alert);
          }
        } catch (e) {
          console.error('err821', e);
          alert('저장실패(err821)');
        }
      },
      async getImageTranslate(arr) {
        try {
          return await axios.post('/api/aiPage/getImageTranslate', arr);
        } catch (e) {
          console.error('err821', e);
          return false;
        }
      },
      async getImageTranslateBatchQuery(requestIds) {
        try {
          const response = await axios.get('http://api.tosoiot.com', {
            params: {
              Action: 'GetImageTranslateBatchQuery',
              RequestIds: requestIds.join(',')
            }
          });
          return response.data;
        } catch (error) {
          console.error('Error fetching image translate batch query:', error);
          throw error;
        }
      },
      async getAITranslate(imgUrl, chArr) {
        const maxRetries = 3;
        let attempts = 0;
        
        while (attempts < maxRetries) {
          try {
            const res = await axios.post('/api/aiPage/getAITranslate', { imgUrl, chArr });
            if (res.data.res === 'success') {
              const result = this.parseMeasurementInfo(res.data.translateResult);
              if (result !== null) {
                return result;
              }
            }
            console.log(`Attempt ${attempts + 1} failed. Retrying...`);
          } catch (e) {
            console.error(`Error on attempt ${attempts + 1}:`, e);
          }
          attempts++;
        }
        
        console.error('All attempts failed');
        return "";
      },
      async getImageOCR(obj) {
        try {
          return await axios.post('/api/aiPage/getImageOCR', obj);
        } catch (e) {
          console.error('err821', e);
          return false;
        }
      },
      parseMeasurementInfo(rawString) {
        const correctedString = rawString.replace(/'/g, '"');
        const jsonString = correctedString.match(/\[.*\]/s)[0];
        try {
          return JSON.parse(jsonString);
        } catch (error) {
          console.error("JSON 문자열을 파싱하지 못했습니다:", error);
          return null;
        }
      },
      deepClone(obj) {
        if (obj === null || typeof obj !== "object") {
          return obj;
        }
        const result = Array.isArray(obj) ? [] : {};
        for (let key of Object.keys(obj)) {
          result[key] = this.deepClone(obj[key]);
        }
        return result;
      }
    },
  }
</script>
  
<style scoped>
.dark-mode {
  background-color: #1a1a1a;
  color: #ffffff;
}

body {
  font-size: 90%;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 10px;
  max-height: 70vh;
  overflow-y: auto;
}

.image-item {
  position: relative;
  cursor: pointer;
}

.image-item img {
  width: 100%;
  height: 80px;
  object-fit: cover;
  transition: all 0.3s ease;
}

.image-item img.selected {
  border: 3px solid #3498db;
  box-shadow: 0 0 10px rgba(52, 152, 219, 0.5);
}

.image-item img.translating {
  filter: blur(5px);
  opacity: 0.7;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
}

.spinner {
  width: 30px;
  height: 30px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.edit-area {
  max-height: 70vh;
  overflow-y: auto;
}

.selected-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.selected-image {
  max-width: 100%;
  max-height: 60vh;
  object-fit: contain;
}

.detail-images-container {
  display: flex;
  flex-direction: column;
}

.detail-image-item {
  width: 100%;
  margin-bottom: 0;
}

.detail-image {
  width: 70%;
  object-fit: contain;
  margin: 0 auto;
  display: block;
}

.current-image {
  border: 3px solid #3498db;
  box-shadow: 0 0 10px rgba(52, 152, 219, 0.5);
}

.no-image-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  background-color: #2c2c2c;
  border: 1px solid #444;
  border-radius: 5px;
  color: #ffffff;
}

.toolbar {
  position: sticky;
  top: 20px;
  background-color: #2c2c2c;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  width: 80%;
  margin: 0 auto;
}

.toolbar button {
  width: 100%;
}
:deep(.full-screen-modal) {
  width: 100vw !important;
  max-width: 100vw !important;
  margin: 0 !important;
  padding: 0 !important;
}

:deep(.full-screen-modal .modal-content) {
  width: 100vw; 
  height: 100vh !important; 
  margin: 0 !important;
  border-radius: 1 !important;
  /* ↑ 모서리가 둥글게 남는 게 싫으면 0으로 설정 */
}

:deep(.full-screen-modal .modal-body) {
  height: 100%;
  overflow: auto;
  padding: 0 !important; /* 안쪽 여백도 없애고 싶다면 */
  margin: 0 !important;
}
</style>