<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="d-flex flex-wrap flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 class="mb-3">{{title}}</h4>
                        <p class="mb-0">{{desc1}}<br> {{desc2}}</p>
                        <!-- <p v-b-modal.modal-3 variant="link" class="mb-0"><u>1688상품 수집 방법</u></p>
                            <b-modal id="modal-3" size="lg" title="상품 수집 방법" ok-only>
                                <p><a href="https://chrome.google.com/webstore/detail/couplus/bogokmehpkdjafnlhjhinfgmeljdgmgh?hl=ko&authuser=0" target="_blank"><u>구글웹스토어</u></a>에 접속해서 "COUPLUS"를 다운받은 후 상품을 수집해 주세요.</p>
                                <img  style="max-width:100%;" :src="require('../../../assets/images/small/extention_collect.png')">
                            </b-modal> -->
                    </div>
                    <!-- <router-link :to="link" class="btn btn-primary add-list"><i class="las la-plus mr-3"></i>+{{linktext}}</router-link> -->
                </div>
            </div>

            <div class="col-lg-12" v-if="showlist">
                <div class="row d-flex flex-column">
                    <CDataTable :headers="headers" :items="aiImageList" v-model="tableSelectedItem" :show-select="true" itemKey="등록번호" class="col-12">
                        <template v-slot:상품명="{item}">
                            <div class="d-flex align-items-center">
                                <img :src="item.등록이미지" class="img-fluid rounded avatar-50 mr-3" alt="image" v-if="item.등록이미지">
                                <div v-if="item.상품명">
                                    {{item.상품명}}
                                </div>
                                <div v-else>
                                    <input type="text" class="form-control" placeholder="상품명입력" v-model="item.상품명init" @change="addProductName(item)">
                                
                                </div>
                            </div>
                        </template>
                        <template v-slot:상세페이지="{item}">
                            <div v-if="!item.상세페이지">
                                <b-badge variant="secondary" v-b-modal="'makePageModal'+item.등록번호">Create</b-badge>
                            </div>
                            <div v-else>
                                <b-badge variant="success">Done</b-badge>
                            </div>

                            <image-editor
                                :id="'makePageModal' + item.등록번호"
                                :item="item"
                                @save="saveItem(item)"
                            />

                        </template>
                        <template v-slot:구매링크="{item}">
                            <div v-if="item.구매링크 !== undefined">
                                <a :href="item.구매링크" target="_blank">{{item.구매링크}}</a>
                                <div v-if="item.showspinner">
                                    <img style="width:20%;" :src="require('../../../assets/images/small/loading.gif')">
                                </div>
                            </div>
                            <div v-else>
                                <input type="text" class="form-control" placeholder="구매링크입력" v-model="item.구매링크init" @change="matchoption1(index,item.구매링크init)">
                            </div>
                        </template>
                    </CDataTable>
                    <div class="col-6 d-flex justify-content-start">
                        <input type="text" class="form-control" placeholder="1688 구매링크 입력" @keyup.enter="addLink" v-model="추가링크">
                        <b-button @click="addLink" variant="outline-primary" class="text-center ml-2 w-25">링크추가</b-button>
                    </div>
                </div>
            </div>

       </div>
       <!-- Page end  -->
   </div>
</template>
<script>
import * as XLSX from 'xlsx';
import axios from 'axios';
import { core } from '../../../config/pluginInit';
import CDataTable from "@/components/common/CDataTable";
import ImageEditor from '../../../components/AI/imageEditor.vue';
// import { transcode } from 'buffer';
export default {
   name:'listproduct',
   components:{
        CDataTable,
        ImageEditor // ImageEditor 컴포넌트를 등록
    },
   data(){
       return{
            headers: [
                {text: '등록번호', value: '등록번호', align: 'center', width: 100, isSlot: false},
                {text: '상품명', value: '상품명', align: 'center', width: 300, isSlot: true},
                {text: '상세페이지', value: '상세페이지', align: 'center', width: 100, isSlot: true},
                // {text: '제품정보', value: '상품명', align: 'left', width: 400, isSlot: true},
                {text: '구매링크', value: '구매링크', align: 'center', width: 100, isSlot: true}
            ],
            tableSelectedItem: [],
			lineselect:false,
           checkalllists: false,
           editMode: 'edit',
           selectedImageSrc: null,
            isAllDetailsSelected: false,
            showOriginal: false,
            thumbAllChecked: false,
            optionAllChecked: false,
            detailAllChecked: false,
            selectedThumbs: [],
            selectedOptions: [],
            selectedDetails: [],

           판매가반올림단위 : 2,
           판매가비율 : 35,
           공급가배율 : 500,
           priceon : false,
           checkapply : true,

           dotranslate : false,
           translatepriority : '추천',
           translated : {},

           추가링크:"",
           번역: '',
           papagoid : '',
           papagosecret : '',

           allcheck : false,

           selectdate: 'today',
           selectoption: '상품수집일',
           startdate: this.getTodaywith(),
           enddate: this.getTodaywith(),

           상품명:'',
           상품번호:'',
           키워드:'',
           등록링크:'',

           reglists: [],
           reglistsinit: [],
           regrowlists: [],


           title:"AI 상세페이지",
           desc1:"1688 썸네일,옵션,상세페이지를 AI번역 및 편집할 수 있는 페이지 입니다.",
           desc2:"",

           showlist: true,
           showspinner: false,
           aiImageList:[],
           ProductList : {
               등록이미지: '',
               구매링크: '',
               옵션1_중국어: '',
               옵션2_중국어: '',
               판매구성수량: '',
               구매가능상태: '',
           },
           selectedImageType: null,

            link:{ name: 'product.addproduct'},
            linktext:"Add Product",
           selected: '',
           isTranslating: false,
           currentRegNum:null,
           currentIndex:0,
           currentType:0,
           showImageEditor: false,
           editorRequestId: null,
            editorImgUrl: null,
            translatingImages: {},

       }
   },
   mounted(){
        this.getItems();
        window.addEventListener('message', this.handleImageEditorMessage);
   },
   beforeDestroy() {
        window.removeEventListener('message', this.handleImageEditorMessage);
    },
   methods: {
        deleteCurrentImage(item) {
            if (confirm('이 이미지를 삭제하시겠습니까?')) {
                if (this.currentType && this.currentIndex !== undefined) {
                    item[this.currentType].splice(this.currentIndex, 1);
                    this.selectedImageSrc = null;
                    this.currentIndex = null;
                    this.currentType = null;
                }
            }
        },
        deleteDetailImage(item, index) {
            if (confirm('이 이미지를 삭제하시겠습니까?')) {
                item.detailImages.splice(index, 1);
            }
        },
        initializeImageEditor() {
            const iframe = document.getElementById('xiangji-image-editor');
            if (iframe) {
                iframe.onload = () => {
                    iframe.contentWindow.postMessage(
                        {
                            name: "XJ_IMAGE_EDITOR_REQUESTIDS",
                            requestIds: [this.editorRequestId]
                        },
                        'https://www.xiangjifanyi.com'
                    );
                };
            } else {
                console.error('Image editor iframe not found');
            }
        },
        handleImageEditorMessage(event) {
            if (event.origin === 'https://www.xiangjifanyi.com' && event.data.name === 'XJ_IMAGE_EDITOR_URL') {
                console.log(event.data);
                // 여기서 편집된 이미지 URL을 처리합니다.
                this.updateEditedImage(event.data);
            }
        },
        updateEditedImage(data) {
            const { url, requestId, all } = data;
            
            const currentItem = this.aiImageList.find(e => e.등록번호 == this.currentRegNum);
            
            if (currentItem) {
                // all 객체의 모든 키(requestId)를 순회
                Object.keys(all).forEach(reqId => {
                    const editedUrl = all[reqId];
                    
                    // thumbnailImages 업데이트
                    this.updateImageArray(currentItem.thumbnailImages, reqId, editedUrl);
                    
                    // optionImages 업데이트
                    this.updateImageArray(currentItem.optionImages, reqId, editedUrl);
                    
                    // detailImages 업데이트
                    this.updateImageArray(currentItem.detailImages, reqId, editedUrl);
                });

                // 현재 선택된 이미지 업데이트
                if (this.currentType && this.currentIndex !== undefined) {
                    const currentImage = currentItem[this.currentType][this.currentIndex];
                    currentImage.img = url;
                    currentImage.translatedImg = url;
                    currentImage.transInfo = {
                        ...currentImage.transInfo,
                        RequestId: requestId
                    };
                    if (this.currentType === 'detailImages') {
                        // Force Vue to re-render the detail images
                        currentItem.detailImages = [...currentItem.detailImages];
                        this.$set(this.translatingImages, this.currentIndex, false);
                    } else {
                        this.selectedImageSrc = url;
                    }
                }
            }

            this.isTranslating = false;
            this.showImageEditor = false;
        },
        updateImageArray(array, requestId, newUrl) {
            const index = array.findIndex(img => img.transInfo && img.transInfo.RequestId === requestId);
            if (index !== -1) {
                array[index].img = newUrl;
                array[index].translatedImg = newUrl;
                array[index].transInfo = {
                    ...array[index].transInfo,
                    RequestId: requestId
                };
            }
        },
        closeImageEditor() {
            this.showImageEditor = false;
            this.isTranslating = false;
        },
        changeMode(item){
            if(this.editMode == 'download'){
                this.thumbAllChecked = true;
                this.optionAllChecked = true;
                this.detailAllChecked = true;
                this.toggleAllThumbs(item);
                this.toggleAllOptions(item);
                this.toggleAllDetails(item);
            }
        },
        async aiImageTranslateAll(item) {
            if (!confirm('전체 이미지를 번역하시겠습니까?')) {
                return;
            }
            if (!item) {
                alert('번역할 아이템이 없습니다.');
                return;
            }

            this.isTranslating = true;

            const allImages = [
                ...item.thumbnailImages.map((img, index) => ({ img, type: 'thumbnailImages', index })),
                ...item.optionImages.map((img, index) => ({ img, type: 'optionImages', index })),
                ...item.detailImages.map((img, index) => ({ img, type: 'detailImages', index }))
            ];

            const totalImages = allImages.length;
            let processedImages = 0;

            for (const { img, type, index } of allImages) {
                try {
                    this.currentType = type;
                    this.currentIndex = index;
                    this.selectedImageSrc = img.translatedImg || img.img;
                    
                    // aiImageTranslate 메소드의 실행이 완료될 때까지 기다립니다.
                    await new Promise(resolve => {
                        this.aiImageTranslate(item).then(() => {
                            processedImages++;
                            console.log(`번역 진행률: ${(processedImages / totalImages * 100).toFixed(2)}%`);
                            resolve();
                        }).catch(error => {
                            console.error(`Error translating ${type} image at index ${index}:`, error);
                            resolve();  // 에러가 발생해도 다음 이미지로 진행
                        });
                    });

                    // 각 이미지 번역 후 잠시 대기 (선택사항)
                    await new Promise(resolve => setTimeout(resolve, 1000));  // 1초 대기

                } catch (error) {
                    console.error(`Unexpected error in translation process for ${type} image at index ${index}:`, error);
                }
            }

            this.isTranslating = false;
            // Force Vue to re-render all image arrays
            item.thumbnailImages = [...item.thumbnailImages];
            item.optionImages = [...item.optionImages];
            item.detailImages = [...item.detailImages];

            alert('전체 이미지 번역이 완료되었습니다.');
        },
        async translateAllDetailImages(item) {
            if (!item.detailImages || item.detailImages.length === 0) {
                alert('번역할 이미지가 없습니다.');
                return;
            }

            for (let i = 0; i < item.detailImages.length; i++) {
                this.$set(this.translatingImages, i, true);
            }

            try {
                const translationPromises = item.detailImages.map((img, index) => 
                this.getImageTranslate([{imgUrl: img.img}])
                    .then(res => {
                    const transInfo = res.data.result[0];
                    img.transInfo = transInfo;
                    img.translatedImg = transInfo.Data.Url;
                    
                    this.$set(this.translatingImages, index, false);
                    
                    return { requestId: transInfo.RequestId, index };
                    })
                    .catch(error => {
                    console.error(`Error translating image at index ${index}:`, error);
                    this.$set(this.translatingImages, index, false);
                    return { error, index };
                    })
                );

                const results = await Promise.all(translationPromises);

                // 성공적으로 번역된 이미지의 RequestId만 필터링
                const successfulRequestIds = results
                .filter(result => !result.error)
                .map(result => result.requestId);

                // 배치 쿼리 실행 (선택적)
                if (successfulRequestIds.length > 0) {
                try {
                    const batchResult = await this.getImageTranslateBatchQuery(successfulRequestIds);
                    console.log('Batch query result:', batchResult);
                } catch (error) {
                    console.error('Batch query error:', error);
                }
                }

            } catch (error) {
                console.error('Translation error:', error);
                alert('이미지 번역 중 오류가 발생했습니다.');
            } finally {
                // Force Vue to re-render the detail images
                item.detailImages = [...item.detailImages];
            }
        },
        async aiImageTranslateDetail(item, index) {
            this.$set(this.translatingImages, index, true);
            try {
                const img = item.detailImages[index];
                const res = await this.getImageTranslate([{imgUrl: img.img}]);
                console.log(res.data.result[0]);
                const transInfo = res.data.result[0];
                img.transInfo = transInfo;
                img.translatedImg = transInfo.Data.Url;
                
                try {
                    const result = await this.getImageTranslateBatchQuery([transInfo.RequestId]);
                    console.log(result);
                } catch (error) {
                    console.log(error)
                }
            } catch (error) {
                console.error('Translation error:', error);
            } finally {
                this.$set(this.translatingImages, index, false);
            }
        },
        async imageEditDetail(item, index) {
            this.$set(this.translatingImages, index, true);
            const img = item.detailImages[index];
            const requestId = img.transInfo ? img.transInfo.RequestId : "";
            const imgUrl = img.translatedImg || img.img;

            console.log(requestId, imgUrl);

            // 이미지 에디터 iframe 생성 및 표시
            this.showImageEditor = true;
            this.currentRegNum = item.등록번호;
            this.editorRequestId = requestId;
            this.editorImgUrl = imgUrl;
            this.currentType = 'detailImages';
            this.currentIndex = index;
        },
        async aiImageTranslate(item){
            if(this.selectedImageSrc && !this.isAllDetailsSelected && !this.isTranslating){
                this.isTranslating = true;
                try {
                    var res = await this.getImageTranslate([{imgUrl:this.selectedImageSrc}]);
                    console.log(res.data.result[0])
                    var transInfo = res.data.result[0];
                    item[this.currentType][this.currentIndex].transInfo = transInfo;
                    item[this.currentType][this.currentIndex].translatedImg = transInfo.Data.Url;
                    
                    try {
                        const result = await this.getImageTranslateBatchQuery([transInfo.RequestId]);
                        console.log(result)
                        var ocrInfo = result.Data.Content[transInfo.RequestId];
                        if(ocrInfo.Orc){
                            console.log(ocrInfo.Orc)
                            var aiResult = await this.getAITranslate(item[this.currentType][this.currentIndex].img,ocrInfo.Orc.map(e => e.source));
                            console.log(aiResult)
                            ocrInfo.AITranslate = aiResult; 
                            var ocrData = [];
                            for(var e of ocrInfo.Orc){
                                var obj = this.deepClone(e);
                                delete obj.id
                                obj.target = aiResult.find(f => f.ch == obj.source) ? aiResult.find(f => f.ch == obj.source).ko[0] : obj.target;
                                ocrData.push(this.deepClone(obj))
                            }
                            if(aiResult && aiResult.length > 0){
                                var transInfoOCR = await this.getImageOCR({
                                    preRequestId: transInfo.RequestId,
                                    url: transInfo.Data.Url,
                                    rmUrl: transInfo.Data.RmUrl,
                                    ocrData: ocrData,
                                });
                                console.log(transInfoOCR)
                                ocrInfo.Url = transInfoOCR.data.result.Data.Url;
                                ocrInfo.RmUrl = transInfoOCR.data.result.Data.RmUrl;
                                ocrInfo.RequestId = transInfoOCR.data.result.RequestId;
                            }
                        }
                        console.log(ocrInfo)
                        item[this.currentType][this.currentIndex].ocrInfo = this.deepClone(ocrInfo);
                    } catch (error) {
                        console.log(error)
                    }
                    this.selectedImageSrc = item[this.currentType][this.currentIndex].ocrInfo.Url;
                    this.isTranslating = false;

                } catch (error) {
                    console.error('Translation error:', error);
                    this.isTranslating = false;
                }
            } 
        },
        async getAITranslate(imgUrl, chArr) {
            const maxRetries = 3;
            let attempts = 0;
            
            while (attempts < maxRetries) {
                try {
                    const res = await axios.post('/api/aiPage/getAITranslate', { imgUrl, chArr });
                    console.log(res);
                    
                    if (res.data.res === 'success') {
                        const result = this.parseMeasurementInfo(res.data.translateResult);
                        if (result !== null) {
                            return result;
                        }
                    }
                    
                    console.log(`Attempt ${attempts + 1} failed. Retrying...`);
                } catch (e) {
                    console.log(`Error on attempt ${attempts + 1}: ${e}`);
                }
                
                attempts++;
            }
            
            console.log('All attempts failed');
            return "";
        },
        parseMeasurementInfo(rawString) {
            const correctedString = rawString.replace(/'/g, '"');

            // 가장 바깥쪽 대괄호 내부의 내용만 추출
            const jsonString = correctedString.match(/\[.*\]/s)[0];
            console.log(jsonString)
            try {
                // JSON 문자열을 JavaScript 객체로 파싱
                const measurementInfoArray = JSON.parse(jsonString);

                // // 배열의 각 객체를 순회
                // measurementInfoArray.forEach((item, index) => {
                //     console.log(`항목 ${index + 1}:`);
                //     console.log(`중국어: ${item.ch}`);
                //     console.log(`한국어:`);
                //     item.ko.forEach((koText, koIndex) => {
                //         console.log(`  ${koIndex + 1}. ${koText}`);
                //     });
                // });

                return measurementInfoArray; // 필요 시 파싱된 배열 반환
            } catch (error) {
                console.log(rawString)
                console.error("JSON 문자열을 파싱하지 못했습니다:", error);
                return null;
            }
        },
        async saveItem(item){
            try{
                var res = await axios.post('/api/aiPage/saveItems',[item])
                // console.log(res)
                if(res.data.res == 'success'){
                    alert('저장완료')
                    return
                } else {
                    alert('저장실패 : ' + res.data.alert);
                    return
                }
            }catch(e){
                console.log('err821' + e);
                alert('저장실패(err821)')
                return 
            }
        },
        async getImageTranslateBatchQuery(requestIds) {
            try {
                const response = await axios.get('http://api.tosoiot.com', {
                params: {
                    Action: 'GetImageTranslateBatchQuery',
                    RequestIds: requestIds.join(',')
                }
                });

                // API 응답 처리
                // console.log(response.data);
                return response.data;
            } catch (error) {
                console.error('Error fetching image translate batch query:', error);
                throw error;
            }
        },
        async imageEdit(item) {
            if (this.selectedImageSrc && !this.isAllDetailsSelected && !this.isTranslating) {
                this.isTranslating = true;
                var img = item[this.currentType][this.currentIndex].transInfo;
                var requestId = img ? img.RequestId : "";
                var imgUrl = img ? img.imgUrl : item[this.currentType][this.currentIndex].img;

                console.log(requestId, imgUrl);

                // 이미지 에디터 iframe 생성 및 표시
                this.showImageEditor = true;
                this.currentRegNum = item.등록번호;
                this.editorRequestId = requestId;
                this.editorImgUrl = imgUrl;
            }
        },
        async getImageTranslate(arr){
            try{
                var res = await axios.post('/api/aiPage/getImageTranslate',arr)
                // console.log(res)
                return res
            }catch(e){
                console.log('err821' + e);
                return false
            }
        },
        async getImageOCR(obj){
            try{
                var res = await axios.post('/api/aiPage/getImageOCR',obj)
                // console.log(res)
                return res
            }catch(e){
                console.log('err821' + e);
                return false
            }
        },
        selectImage(img,index,type) {
            this.currentType = type;
            this.currentIndex = index;
            this.selectedImageSrc = img.translatedImg ? img.translatedImg : img.img;
            this.isAllDetailsSelected = false;
            this.selectedImageType = type;
        },
        selectAllDetails(item) {
            this.selectedDetails = this.detailAllChecked ? item.detailImages.map(() => true) : [];
            this.isAllDetailsSelected = true;
            this.selectedImageSrc = null;
        },
        toggleAllThumbs(item) {
            this.selectedThumbs = this.thumbAllChecked ? item.thumbnailImages.map(() => true) : [];
        },
        toggleAllOptions(item) {
            this.selectedOptions = this.optionAllChecked ? item.optionImages.map(() => true) : [];
        },
        toggleAllDetails(item) {
            this.selectedDetails = this.detailAllChecked ? item.detailImages.map(() => true) : [];
        },
        addProductName(list){
            list.상품명 = list.상품명init;
        },
        async getProductInfo(구매링크){
            var productNo = 구매링크.slice(구매링크.search('offer/')+6,구매링크.search('.html'));
            console.log(productNo)
            try{
                var res = await axios.post('/api/aiPage/getProductInfo',[{productId:parseInt(productNo)}])
                console.log(res)
                return res
            }catch(e){
                console.log('err821' + e);
                return false
            }
        },
        async addLink(){
            if(!this.추가링크){
                alert('1688상품 링크를 입력해 주세요.')
                return
            }
            if(!this.추가링크.includes('detail.1688.com')){
                alert('올바른 1688상품 링크를 입력해 주세요.')
                return
            }
            var res = await this.getProductInfo(this.추가링크);
            var data = res.data.result[0].result.result
            console.log(data)
            // console.log(data.productImage.images[0])
            var now = new Date().getTime();

            const parser = new DOMParser();
            const doc = parser.parseFromString(data.description, 'text/html');

            // 모든 <img> 요소를 선택합니다.
            const imgElements = doc.querySelectorAll('img');

            // 이미지 로드 및 필터링을 비동기적으로 처리합니다.
            const imagePromises = Array.from(imgElements).map(img => this.loadImage(img.src));
            // 모든 이미지 로드가 완료된 후 결과를 필터링합니다.
            const results = await Promise.all(imagePromises);
            this.imageSources = results.filter(src => src !== null);

            // 각 <img> 요소의 src 속성 값을 수집하여 배열로 만듭니다.
            var imageSources = Array.from(imgElements).map(img => img.src);
            var thumbnail = [];
            var option = [];
            var detail = [];
            data.productImage.images.forEach(e => {
                thumbnail.push({
                    img:e,
                    selectedThumbs:false,
                })
            })
            console.log(data.productSkuInfos)
            console.log(data.productSkuInfos.map(e => e.skuAttributes[0].skuImageUrl))
            data.productSkuInfos.map(e => e.skuAttributes[0].skuImageUrl).filter(e => e && e.length > 10).forEach(e => {
                option.push({
                    img:e,
                    selectedOptions:false,
                })
            })
            imageSources.filter(e => e.length > 10).forEach(e => {
                detail.push({
                    img:e,
                    selectedDetails:false,
                })
            })
            console.log(thumbnail)
            console.log(option)
            console.log(detail)

            this.aiImageList.push({
                data : data,
                등록번호 : now,
                상품명:"",
                상품명init:"",
                등록이미지:data.productImage.images[0],
                구매링크:this.추가링크.split('?')[0],
                showspinner:false,
                상세페이지:false,
                thumbnailImages:thumbnail,
                optionImages:option,
                detailImages:detail,
            })
        },
        loadImage(src) {
            return new Promise((resolve) => {
                const image = new Image();
                image.src = src;
                image.onload = () => {
                    if (image.naturalWidth > 50 && image.naturalHeight > 50) {
                        resolve(image.src);
                    } else {
                        resolve(null);
                    }
                };
                image.onerror = () => {
                    resolve(null);
                };
            });
            },
        classImage(){
            if(this.editMode == 'edit'){
                return "col-lg-2 d-flex flex-column"
            } else {
                return "col-lg-7 d-flex flex-column"
            }
        },
       canceledit(index){
           if(this.priceon){
               this.priceon = false;
           }
           this.reglists[index].상세내역 = [];
           this.reglists[index].상품명kr = this.reglistsinit[index].상품명kr;
           this.reglistsinit[index].상세내역.forEach(e => {
               this.reglists[index].상세내역.push(this.deepClone(e))
           })
       },
       makepageconfirm(index,foo){
           if(foo){
               if(confirm('상세페이지 생성을 취소하시겠습니까?')){
                   this.reglists[index].상세페이지 = false;
               }
           } else {
               this.$bvModal.show('makepagemodal' + index);
           }
       },
       resetreg(reg){
           reg.상품명kr = '';
           reg.상세내역.forEach(e => {
               e.상품명kr = '';
               e.옵션1_한국어 = '';
               e.옵션2_한국어 = '';
           })
       },
       checkalllist(){
           if(this.checkalllists){
               this.reglists.forEach(e => {
                   e.checked = true;
               })
           } else {
               this.reglists.forEach(e => {
                   e.checked = false;
               })
           }
       },
       async deletecollectlists(){
           if(this.reglists.filter(e => e.checked).length > 0){
               if(this.reglists.filter(e => e.checked).length == this.reglists.length){
                   if(confirm('모든 상품을 삭제하시겠습니까?')){
                       await this.deletereglists(this.reglists)
                       this.reglists = [];
                   } else {
                       return
                   }
               }
               if(confirm(this.reglists.filter(e => e.checked).length + '개의 상품을 삭제하시겠습니까?')){
                   var arr = [];
                   var dellist = [];
                   this.reglists.forEach(e => {
                       if(!e.checked){
                           arr.push(e)
                       } else {
                           dellist.push(e)
                       }
                   })
                   await this.deletereglists(dellist)
                   this.reglists = arr;
               }
           } else {
               alert('삭제할 상품을 선택해 주세요')
           }
       },
       detailpageclass(foo){
           if(foo){
               return 'badge badge-success';
           } else {
               return 'badge badge-warning';
           }
       },
       savereglists(){
           if(this.reglists.filter(e => e.checked).length > 0){
               var arr = [];
               this.reglists.forEach(e => {
                   if(e.checked){
                       arr.push(e)
                   }
               })
               this.savereg(arr)
           } else {
               this.savereg(this.reglists)
           }
       },
       savereg(arr){
           axios.post('/api/regproduct/savereglist',arr)
           .then((res) => {
               console.log(res)
               this.$store.commit('settingsupdate',res.data.settings)
               alert(res.data.요청결과)
           })
           .catch(e => {
               console.log(e);
               alert('요청실패(282)')
           })
       },
       reqcollect(reg){
           axios.post('/api/regproduct/reqcollect',{
               companyid : this.$store.state.user.companyid,
               등록링크 : reg.등록링크,
           })
           .then((res) => {
               if(res.data.요청결과 == 'success'){
                   alert('관리자에 등록요청을 하였습니다. 등록완료까지 최대 24시간 소요될 수 있습니다.')
               }
           })
           .catch(e => {
               console.log(e);
               alert('요청실패(424)')
           })
       },
       deleteindexfromarray(array,index){
           return array.slice(0,index).concat(array.slice(index+1))
       },
       async deletedetailitem(reg,index){
           if(confirm('삭제하시겠습니까?')){
               console.log(reg)
               console.log(index)
               var arr = [];
               reg.상세내역.forEach((e,i) => {
                   if(i !== index){
                       arr.push(e)
                   }
               })
               console.log(arr)
               reg.상세내역 = arr;
           }
       },
       async deleteAll(reg){
           if(reg.상세내역.filter(e => e.checked).length == 0){
               alert('삭제할 내역을 선택해 주세요')
           } else {
               if(confirm(reg.상세내역.filter(e => e.checked).length + '개의 내역을 삭제하시겠습니까?')){
                   var arr = [];
                   reg.상세내역.forEach(e =>{
                       if(!e.checked){
                           arr.push(e)
                       }
                   })
                   reg.상세내역 = arr
               }
           }
           
       },
       async deleteitem(index){
           if(confirm('삭제하시겠습니까?')){
               this.showlist = false;
               setTimeout(async () => {
                   var arr = [];
                   arr.push(this.reglists[index])
                   await this.deletereglists(arr)
                   var ar = [];
                   this.reglists.forEach((e,i) => {
                       if(i !== index){
                           ar.push(e)
                       }
                   })
                   this.reglists = ar;
                   this.showlist = true;
               },10)
           }
       },
       deletereglists(arr){
           axios.post('/api/regproduct/deletelist',arr)
           .then((res) => {
               if(res.data.요청결과 == 'success'){
                   alert('총 ' + res.data.요청수 + '개의 상품이 삭제되었습니다.')
               }
           })
           .catch(e => {
               console.log(e);
               alert('요청실패(705)')
           })
       },
       getdate(timestamp){
           var date = new Date(timestamp);
           var year = date.getFullYear();
           var month = ("0" + (1 + date.getMonth())).slice(-2);
           var day = ("0" + date.getDate()).slice(-2);

           return year + "-" + month + "-" + day;
       },  
       changedate(){
           var now
           var target
           if(this.selectdate == 'today'){
               this.startdate = this.getTodaywith();
               this.enddate = this.getTodaywith();
           } else {
               if(this.selectdate == 'week'){
                   now = new Date(this.getTodaywith());
                   target = now - 1000*60*60*24*7
                   
                   this.startdate = this.getdate(target);
                   this.enddate = this.getTodaywith();
               } else {
                   if(this.selectdate == 'month'){
                   now = new Date(this.getTodaywith());
                   target = now - 1000*60*60*24*30
                   this.startdate = this.getdate(target);
                   this.enddate = this.getTodaywith();
                   }
               }
           }
       },
       makepage(reg){
           var regconfirm = false
           if(reg.상세내역.find(e => e.옵션1_중국어 && !e.옵션1_한국어)){
               alert('등록옵션번호:' + reg.상세내역.find(e => e.옵션1_중국어 && !e.옵션1_한국어).등록옵션번호 + '의 한국어옵션1이 기입되지 않았습니다.')
               regconfirm = true
           }
           if(reg.상세내역.find(e => e.옵션2_중국어 && !e.옵션2_한국어)){
               alert('등록옵션번호:' + reg.상세내역.find(e => e.옵션2_중국어 && !e.옵션2_한국어).등록옵션번호 + '의 한국어옵션2가 기입되지 않았습니다.')
               regconfirm = true
           }
           if(reg.상세내역.find(e => e.상품명 && !e.상품명kr)){
               alert('등록옵션번호:' + reg.상세내역.find(e => e.상품명 && !e.상품명kr).등록옵션번호 + '의 한국어상품명이 기입되지 않았습니다.')
                   regconfirm = true
           }
           if(regconfirm){
               return
           }
           reg.상세페이지 = true;
           this.makepagerequest(reg)
       },
       makepagerequest(reg){
           var obj = this.deepClone(reg);
           var option1array = [];
           
           reg.상세내역.forEach(e => {
               if(option1array.filter(ele => ele == e.옵션1_한국어).length == 0){
                   option1array.push(e.옵션1_한국어)
               }
           })
           obj.option1array = option1array;
           obj.minimagepixel = 400;
           obj.maxthumbqty = 5;
           window.postMessage({greeting: "makepage",reg:obj},"*",)
           console.log(reg)
       },
       downloadexcel(reg){
           var arr = [];
           var obj = {};
           console.log(reg)
           reg.상세내역.forEach(e => {
               obj = {
                   등록번호 : e.등록번호,
                   등록옵션번호 : e.등록옵션번호,
                   '상품명(등록용)' : e.옵션2_한국어 ? e.상품명kr + ', ' + e.옵션1_한국어 + ', ' + e.옵션2_한국어 : e.옵션1_한국어 ? e.상품명kr + ', ' + e.옵션1_한국어 : e.상품명kr,
                   '구매가격(CNY)' : e['구매가격(CNY)'],
                   '공급가(등록용)' : e['구매가격(CNY)'] * this.공급가배율,
                   '쿠팡판매가(등록용)' : Math.round((e['구매가격(CNY)'] * this.공급가배율 / ((100-this.판매가비율)/100)) / (10 ** this.판매가반올림단위)) * (10 ** this.판매가반올림단위),
                   '상품명(한국어)' : e.상품명kr,
                   '옵션1(한국어)' : e.옵션1_한국어,
                   '옵션2(한국어)' : e.옵션2_한국어,
                   '상품명(중국어)' : e.상품명,
                   '옵션1(중국어)' : e.옵션1_중국어,
                   '옵션2(중국어)' : e.옵션2_중국어,
                   '재고' : e.재고,
                   '등록링크' : e.등록링크,
                   '옵션이미지링크' : e.옵션1_이미지,
               }
               arr.push(obj)
           })
           const workBook = XLSX.utils.book_new()
           const workSheet1 = XLSX.utils.json_to_sheet(arr)
           XLSX.utils.book_append_sheet(workBook, workSheet1, '상품등록리스트')
           XLSX.writeFile(workBook, '상품등록리스트_'+ reg.등록번호 +'.xlsx')
       },
       async checkpage(){
           var res = await core.checkpage()
           return res
       },  
       // addscript(){
       //     let googletranslateScript = document.createElement('script')
       //     googletranslateScript.setAttribute('src', 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit')
       //     document.head.appendChild(googletranslateScript)
       // },
       // googleTranslateElementInit(){
       //     new google.translate.TranslateElement({pageLanguage: 'ko',autoDisplay: true}, 'datatable');
       // },
       allpage(num){
           core.allpage(num)
       },
       changename(reg){
           if(this.checkapply){
               this.reglistsinit.filter(e => e.등록번호 == reg.등록번호 && e.상품명 == reg.상품명).forEach(e => {
                   e.상품명kr = reg.상품명kr;
                   e.상세내역.forEach(ele => {
                       ele.상품명kr = reg.상품명kr;
                   })                    
               })
               this.reglists.filter(e => e.등록번호 == reg.등록번호 && e.상품명 == reg.상품명).forEach(e => {
                   e.상품명kr = reg.상품명kr;
                   e.상세내역.forEach(ele => {
                       ele.상품명kr = reg.상품명kr;
                   })
               })
           }
           reg.shownameinput = false;
           reg.changed = true;
       },
       changeop1(reg){
           if(this.checkapply){
               this.reglistsinit.filter(e => e.등록번호 == reg.등록번호 && e.상품명 == reg.상품명).forEach(e => {
                   e.상세내역.filter(ele => ele.옵션1_중국어 == reg.옵션1_중국어).forEach(ele => {
                       ele.옵션1_한국어 = reg.옵션1_한국어;
                   })
               })
               this.reglists.filter(e => e.등록번호 == reg.등록번호 && e.상품명 == reg.상품명).forEach(e => {
                   e.상세내역.filter(ele => ele.옵션1_중국어 == reg.옵션1_중국어).forEach(ele => {
                       ele.옵션1_한국어 = reg.옵션1_한국어;
                   })
               })                
           }
           reg.showop1input = false;
           reg.changed = true;
       },
       changeop2(reg){
           if(this.checkapply){
               this.reglistsinit.filter(e => e.등록번호 == reg.등록번호 && e.상품명 == reg.상품명).forEach(e => {
                   e.상세내역.filter(ele => ele.옵션2_중국어 == reg.옵션2_중국어).forEach(ele => {
                       ele.옵션2_한국어 = reg.옵션2_한국어;
                   })
               })
               this.reglists.filter(e => e.등록번호 == reg.등록번호 && e.상품명 == reg.상품명).forEach(e => {
                   e.상세내역.filter(ele => ele.옵션2_중국어 == reg.옵션2_중국어).forEach(ele => {
                       ele.옵션2_한국어 = reg.옵션2_한국어;
                   })
               })               
           }
           reg.showop2input = false;
           reg.changed = true;
       },
       productnameinit(reg){
           reg.상품명kr = '';
           reg.상품명 = '';
       },
       getpapagoapi(){
           console.log(this.$store.state.settings[0])
           if(this.$store.state.settings[0].papagoid && this.$store.state.settings[0].papagosecret){
               this.papagoid = this.$store.state.settings[0].papagoid;
               this.papagosecret = this.$store.state.settings[0].papagosecret;
           } else {
               alert('옵션명 번역을 위해 파파고 번역 API Key를 먼저 발급받아주세요.');
               this.$bvModal.show('modalsetapi');
           }
       },
       savepapagoapi(){
           if(!this.papagoid || !this.papagosecret){
               alert('API Key 발급 방법에 따라 발급 후 Client ID와 ClientSecret을 입력한 후 저장해주세요')
               return 
           }
           axios.post('/api/setpapagoapi',{
               id : this.papagoid,
               secret : this.papagosecret
           })
           .then((res) => {
               console.log(res)
               this.$store.commit('settingsupdate',res.data.settings)
               alert(res.data.요청결과)
           })
           .catch(e => {
               console.log(e);
               alert('요청실패(282)')
           })
       },
       getTodaywith(){
           var date = new Date();
           var year = date.getFullYear();
           var month = ("0" + (1 + date.getMonth())).slice(-2);
           var day = ("0" + date.getDate()).slice(-2);

           return year + "-" + month + "-" + day;
       }, 
       checkall(reg){
           if(this.allcheck){
               reg.상세내역.forEach(e => e.checked = true)
           } else {
               reg.상세내역.forEach(e => e.checked = false)
           }
       },
       edit(ProductList){
           this.$store.state.productdb.some(e => {
               if(e["SKU ID"] == ProductList["SKU ID"]){
                   e.등록이미지 = ProductList.등록이미지;
                   e.구매링크 = ProductList.구매링크;
                   e.옵션1_중국어 = ProductList.옵션1_중국어;
                   e.옵션2_중국어 = ProductList.옵션2_중국어;
                   e.판매구성수량 = ProductList.판매구성수량;
                   e.구매가능상태 = ProductList.구매가능상태;
                   axios.post('/api/addproduct/editproductdb',ProductList)
                   .then((res) => {console.log(res.data.요청결과)})
                   .catch(console.log)
                   return (e["SKU ID"] == ProductList["SKU ID"])
               }
           })
       },
       MenuUpdate() {
           console.log(this.selected)
           if (this.selected == "radio1") {
               this.menuint = this.menuDefault;
           } else {
               if (this.selected == "radio2") {
                   this.menuint = this.menuCost;
               }
               //  else {
               //     this.menuint = this.menuSales;
               // }
           }
       },
       modalID(index) {
           return 'modal' + index
       },
       modaledit(index) {
           return 'modaledit' + index
       },        
       searchpd(event) {
           if(event.keyCode === 13){
               this.search();
           }
       },
       async fetchreglists(){
           var res = await axios.post('/api/regproduct/getlist',{companyid:this.$store.state.user.companyid,start:this.startdate,end:this.enddate}).catch(e => {console.log(e);return false})
           if(res){
               return res.data.result
           }
       },
       deepClone(obj) {
           if (obj === null || typeof obj !== "object") {
               return obj
           }

           const result = Array.isArray(obj) ? [] : {}

           for (let key of Object.keys(obj)) {
               result[key] = this.deepClone(obj[key])
           }

           return result
       },   
       // async applytranslate(){
       //     var pageinfo = await this.checkpage()
       //     // this.googleTranslateElementInit();
       //     for(var i=pageinfo.start;i<pageinfo.end;i++){
       //         var j = i-pageinfo.start;
       //         if(!this.reglists[i].상품명kr){
       //             var 상품명kr = document.getElementById('datatable2').children[1].children[j].children[2].innerText;
       //             this.reglists[i].상품명kr = 상품명kr;
       //             this.reglists[i].상세내역.forEach(e => {
       //                 e.상품명kr = 상품명kr;
       //             })                 
       //         }
       //         this.reglists[i].shownameinput = false;
       //         this.reglists[i].changed = true;
       //     }
       // },
       async applytranslateoption(index){
           // var pageinfo = await this.checkpage()
           // this.googleTranslateElementInit();
           var i = index
           for(var j=0;j<document.getElementById('datatable2').children[1].children.length;j++){
               var 상품명kr = document.getElementById('datatable2').children[1].children[j].children[2].innerText;
               var 옵션1_한국어 = document.getElementById('datatable2').children[1].children[j].children[6].innerText;
               var 옵션2_한국어 = document.getElementById('datatable2').children[1].children[j].children[7].innerText;
               this.reglists[i].상품명kr = 상품명kr;
               this.reglists[i].상세내역[j].상품명kr = 상품명kr;
               this.reglists[i].상세내역[j].옵션1_한국어 = 옵션1_한국어;
               this.reglists[i].상세내역[j].옵션2_한국어 = 옵션2_한국어;
               this.reglists[i].shownameinput = false;
               this.reglists[i].changed = true;
           }
       },
       async translate(text){
           if(this.dotranslate){
               if(this.translated[text]){
                   return this.translated[text]
               }
               var res = await axios.post('/api/translate',{
                   id : this.papagoid,
                   secret : this.papagosecret,
                   text : text
               })
               .catch(e => {
                   console.log(e);
                   if(e.response.status == 429){
                       alert('파파고API의 하루요청 최대 횟수를 초과하였습니다.' + '\n' + '(5000자/회, 하루 10000자까지)')
                       this.dotranslate = false;
                       return {status : 429}
                   } else {
                       alert('번역실패')
                       return {status : e.response.status}
                   }
               })
               if(res.status == 200){
                   console.log(res)
                   this.translated[text] = res.data.message.result.translatedText;
                   return res.data.message.result.translatedText
               } else {
                   return ''   
               }
           } else {
               return ''
           }
       },
       padLeft(nr, n, str){
           return Array(n-String(nr).length+1).join(str||'0')+nr;
       },
       async getAIPageList(){
            try{
                var res = await axios.post('/api/aiPage/getList',[])
                if(res){
                    return res.data.result
                } else {
                    return [];
                }
            }catch(e){
                return []
            }
       },
       async getItems() {
           this.showspinner = true;
           this.showlist = false;
           this.aiImageList = [];
           var res = await this.getAIPageList()
           if(res.length == 0){
               alert('수집된 내역이 없습니다.')
               this.showspinner = false;
               this.showlist = true;
               return
           }
           
           for(const e of res){
                this.aiImageList.push(e);
           }

           this.$nextTick(() => {
                this.showlist = true;
                this.showspinner = false;
            })
       },
       async search() {
           this.showspinner = true;
           this.reglists = [];
           this.reglistsinit = [];
           this.regrowlists = [];
           this.showlist = false;
           var res = await this.fetchreglists()
           if(res.length == 0){
               alert('수집된 내역이 없습니다.')
               this.showspinner = false;
               return
           }
           // console.log(res)
           var obj = {};
           var 상품명 = '';
           var 옵션1_한국어 = '';
           var 옵션2_한국어 = '';
           var 옵션조합_한국어 = '';
           var 구매가격 = 0;
           var cnt = 0;
           for(const e of res){
               if(e.save){
                   e.save.상세내역.forEach(ele => {
                       this.regrowlists.push(this.deepClone(ele))
                   })
               } else {
                   상품명 = '';
                   옵션1_한국어 = '';
                   옵션2_한국어 = '';
                   옵션조합_한국어 = '';
                   구매가격 = 0;
                   console.log(e.options)
                   if(e.상품명){
                       상품명 = e.상품명;
                   } else {
                       if(e.options && e.options.totalinfo && e.options.totalinfo.globalData){
                           상품명 = e.options.totalinfo.globalData.tempModel.offerTitle
                       }
                   }
                   if(!상품명){
                       if(e.options && e.options.totalinfo && e.options.totalinfo.globalData){
                           상품명 = e.options.totalinfo.globalData.tempModel.offerTitle
                       } else {
                           상품명 = ''
                       }
                   }
                   if(e.options && e.options.option1array.length > 0){
                       if(e.options.option2array.length > 0){
                           for(const [i,el1] of e.options.option1array.entries()){
                               for(const [j,el2] of e.options.option2array.entries()){
                                   if(e.options.option1arraykr && e.options.option1arraykr[i]){
                                       if(e.options.option2arraykr && e.options.option2arraykr[j]){
                                           옵션1_한국어 = e.options.option1arraykr[i];
                                           옵션2_한국어 = e.options.option2arraykr[j];
                                           옵션조합_한국어 = 옵션1_한국어 + ', ' + 옵션2_한국어;
                                       } else {
                                           옵션1_한국어 = e.options.option1arraykr[i];
                                           옵션2_한국어 = '';
                                           옵션조합_한국어 = 옵션1_한국어 + ', ' + 옵션2_한국어;
                                       }
                                   } else {
                                       옵션1_한국어 = '';
                                       옵션2_한국어 = '';
                                       옵션조합_한국어 = '';
                                   }
                                   if(e.options.totalinfo.globalData){
                                       if(e.options.totalinfo.globalData.orderParamModel.orderParam.skuParam.skuPriceType == 'skuPrice'){
                                           if(e.options.totalinfo.globalData.skuModel.skuInfoMap[el1.name + '&gt;' + el2.name]){
                                               구매가격 = e.options.totalinfo.globalData.skuModel.skuInfoMap[el1.name + '&gt;' + el2.name].discountPrice;
                                           } else {
                                               구매가격 = 0
                                           }
                                       } else {
                                           구매가격 = e.options.totalinfo.globalData.orderParamModel.orderParam.skuParam.skuRangePrices[0].price;
                                       }
                                   } else {
                                       구매가격 = 0
                                   }
                                   
                                   

                                   cnt++
                                   obj = {
                                       _id : e._id,
                                       checked : false,
                                       shownameinput : false,
                                       showop1input : false,
                                       showop2input : false,
                                       changed : false,
                                       showrequest : false,
                                       mdcomment : '',
                                       '구매가격(CNY)' : parseInt(구매가격),
                                       썸네일 : e.options.totalinfo.globalData.images[0].fullPathImageURI,
                                       상세페이지 : false,
                                       등록번호 : e.regnum,
                                       등록옵션번호 : e.regnum + this.padLeft(cnt,3),
                                       상품명 : 상품명 ? 상품명 : '',
                                       상품명kr : e.productname ? e.productname : '',
                                       상품특징 : e.상품특징 ? e.상품특징 : '',
                                       키워드 : e.키워드 ? e.키워드 : '',
                                       상품번호 : e.productnum ? e.productnum : '',
                                       등록링크 : e.productnum ? 'https://detail.1688.com/offer/' + e.productnum + '.html' : '',
                                       옵션1_이미지 : el1.imageUrl ? el1.imageUrl : '',
                                       옵션1_중국어 : el1.name ? el1.name : '',
                                       옵션2_중국어 : el2.name ? el2.name : '',
                                       옵션1_한국어 : 옵션1_한국어,
                                       옵션2_한국어 : 옵션2_한국어,
                                       // 옵션조합_중국어 : e.옵션조합_중국어 ? e.옵션조합_중국어 : '',
                                       재고 : e.options.optionstock[e.options.optioncomb.findIndex(f => f == el1.name + '&' + el2.name)],
                                       옵션조합_한국어 : 옵션조합_한국어
                                   }
                                   this.regrowlists.push(this.deepClone(obj))
                               }  
                           }
                       } else {
                           // console.log(e.options)
                           for(const [i,el1] of e.options.option1array.entries()){
                               if(e.options.option1arraykr && e.options.option1arraykr[i]){
                                   옵션1_한국어 = e.options.option1arraykr[i];
                               } else {
                                   옵션1_한국어 ='';
                               }   
                               if(e.options.totalinfo.globalData){
                                   if(e.options.totalinfo.globalData.orderParamModel.orderParam.skuParam.skuPriceType == 'skuPrice'){
                                       구매가격 = e.options.totalinfo.globalData.skuModel.skuInfoMap[el1.name].discountPrice;
                                   } else {
                                       if(e.options.totalinfo.globalData.orderParamModel.orderParam.skuParam.skuRangePrices){
                                           구매가격 = e.options.totalinfo.globalData.orderParamModel.orderParam.skuParam.skuRangePrices[0].price;
                                       } else {
                                           구매가격 = 0;
                                       }
                                   } 
                               } else {
                                   구매가격 = 0;
                               }
                               
                               cnt++
                               obj = {
                                   _id : e._id,
                                   checked : false,
                                   shownameinput : false,
                                   showop1input : false,
                                   showop2input : false,
                                   changed : false,
                                   showrequest : false,
                                   mdcomment : '',
                                   '구매가격(CNY)' : parseInt(구매가격),
                                   썸네일 : e.options.totalinfo.globalData ? e.options.totalinfo.globalData.images[0].fullPathImageURI : '',
                                   상세페이지 : false,
                                   등록번호 : e.regnum,
                                   등록옵션번호 : e.regnum + this.padLeft(cnt,3),
                                   상품명 : 상품명 ? 상품명 : '',
                                   상품명kr : e.productname ? e.productname : '',
                                   상품특징 : e.상품특징 ? e.상품특징 : '',
                                   키워드 : e.키워드 ? e.키워드 : '',
                                   상품번호 : e.productnum ? e.productnum : '',
                                   등록링크 : e.productnum ? 'https://detail.1688.com/offer/' + e.productnum + '.html' : '',
                                   옵션1_이미지 : el1.imageUrl ? el1.imageUrl : '',
                                   옵션1_중국어 : el1.name ? el1.name : '',
                                   옵션2_중국어 : '',
                                   옵션1_한국어 : 옵션1_한국어,
                                   옵션2_한국어 : '',
                                   // 옵션조합_중국어 : e.옵션조합_중국어 ? e.옵션조합_중국어 : '',
                                   재고 : e.options.optionstock[e.options.optioncomb.findIndex(f => f == el1.name)],
                                   옵션조합_한국어 : 옵션1_한국어,
                               }
                               this.regrowlists.push(this.deepClone(obj))
                           }
                       }
                   } else {
                       if(e.options){
                           구매가격 = e.options.totalinfo.globalData.orderParamModel.orderParam.skuParam.skuRangePrices[0].price;
                           cnt++
                           obj = {
                               _id : e._id,
                               checked : false,
                               shownameinput : false,
                               showop1input : false,
                               showop2input : false,
                               changed : false,
                               showrequest : false,
                               mdcomment : '',
                               '구매가격(CNY)' : parseInt(구매가격),
                               썸네일 : e.options.totalinfo.globalData.images[0].fullPathImageURI,
                               상세페이지 : false,
                               등록번호 : e.regnum,
                               등록옵션번호 : e.regnum + this.padLeft(cnt,3),
                               상품명 : 상품명 ? 상품명 : '',
                               상품명kr : e.productname ? e.productname : '',
                               상품특징 : e.상품특징 ? e.상품특징 : '',
                               키워드 : e.키워드 ? e.키워드 : '',
                               상품번호 : e.productnum ? e.productnum : '',
                               등록링크 : e.productnum ? 'https://detail.1688.com/offer/' + e.productnum + '.html' : '',
                               옵션1_이미지 : e.options.totalinfo.globalData.images[0].fullPathImageURI,
                               옵션1_중국어 : '',
                               옵션2_중국어 : '',
                               옵션1_한국어 : '',
                               옵션2_한국어 : '',
                               // 옵션조합_중국어 : e.옵션조합_중국어 ? e.옵션조합_중국어 : '',
                               재고 : e.options.totalinfo.globalData.orderParamModel.orderParam.canBookedAmount,
                               옵션조합_한국어 : '',
                           }
                           this.regrowlists.push(this.deepClone(obj))
                       } else {
                           구매가격 = 0;
                           cnt++
                           obj = {
                               _id : e._id,
                               checked : false,
                               shownameinput : false,
                               showop1input : false,
                               showop2input : false,
                               changed : false,
                               showrequest : true,
                               mdcomment : '',
                               '구매가격(CNY)' : '',
                               썸네일 : '',
                               상세페이지 : false,
                               등록번호 : '',
                               등록옵션번호 : '',
                               상품명 : '',
                               상품명kr : '',
                               상품특징 : '',
                               키워드 : '',
                               상품번호 : e.productnum ? e.productnum : '',
                               등록링크 : e.productnum ? 'https://detail.1688.com/offer/' + e.productnum + '.html' : '',
                               옵션1_이미지 : '',
                               옵션1_중국어 : '',
                               옵션2_중국어 : '',
                               옵션1_한국어 : '',
                               옵션2_한국어 : '',
                               // 옵션조합_중국어 : e.옵션조합_중국어 ? e.옵션조합_중국어 : '',
                               재고 : '',
                               옵션조합_한국어 : '',
                           }
                           this.regrowlists.push(this.deepClone(obj))                       
                       }
                   }  
               } 
           }
           // console.log(this.regrowlists)
           var ob = {};
           this.regrowlists.forEach(e => {
               if(this.reglists.length > 0){
                   if(this.reglists.find(ele => ele.상품번호 == e.상품번호 && ele.등록번호 == e.등록번호)){
                       this.reglists.find(ele => ele.상품번호 == e.상품번호 && ele.등록번호 == e.등록번호).상세내역.push(this.deepClone(e))
                       this.reglistsinit.find(ele => ele.상품번호 == e.상품번호 && ele.등록번호 == e.등록번호).상세내역.push(this.deepClone(e))
                   } else {
                       ob = {
                           _id : e._id,
                           checked : e.checked,
                           shownameinput : e.shownameinput,
                           changed : e.changed,
                           showrequest : e.showrequest,
                           mdcomment : e.mdcomment,
                           썸네일 : e.썸네일,
                           상세페이지 : e.상세페이지,
                           등록번호 : e.등록번호,
                           상품명 : e.상품명,
                           상품명kr : e.상품명kr,
                           상품특징 : e.상품특징,
                           키워드 : e.키워드,
                           상품번호 : e.상품번호,
                           등록링크 : e.등록링크,
                           상세내역 : [e]
                       }
                       this.reglists.push(this.deepClone(ob))
                       this.reglistsinit.push(this.deepClone(ob))
                   }
               } else {
                   ob = {
                       _id : e._id,
                       checked : e.checked,
                       shownameinput : e.shownameinput,
                       changed : e.changed,
                       showrequest : e.showrequest,
                       mdcomment : e.mdcomment,
                       썸네일 : e.썸네일,
                       상세페이지 : e.상세페이지,
                       등록번호 : e.등록번호,
                       상품명 : e.상품명,
                       상품명kr : e.상품명kr,
                       상품특징 : e.상품특징,
                       키워드 : e.키워드,
                       상품번호 : e.상품번호,
                       등록링크 : e.등록링크,
                       상세내역 : [e]
                   }
                   this.reglists.push(this.deepClone(ob))
                   this.reglistsinit.push(this.deepClone(ob))
               }
           })
           console.log(this.reglists)

           this.showlist = true;
           this.showspinner = false;
           
           
       }
   }
}
</script>
<style scoped>
.detail-image-container {
    position: relative;
}

.detail-image-container img.translating {
    filter: blur(5px);
    opacity: 0.7;
}

.detail-image-container .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
}

.detail-image-container:hover .image-overlay {
    opacity: 1;
}

.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay-btn {
    padding: 10px 15px;
    font-size: 16px;
    margin: 0 10px;
}

   .modal-dialog {
       max-width: 100%;
       position: absolute;
       margin: auto;
       top: 0;
       bottom: 0;
       left: 0;
       right: 0;
   }
   .image-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
}

.image-container:hover .image-overlay {
    opacity: 1;
}

.selected-image-wrapper {
    position: relative;
    display: inline-block;
}

.selected-image {
    max-width: 100%;
    height: auto;
    display: block;
}

.translating {
    filter: blur(5px);
    opacity: 0.7;
}

.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selected-image-wrapper:hover .image-overlay {
    opacity: 1;
}

.overlay-btn {
    padding: 10px 15px;
    font-size: 16px;
    margin: 0 5px;
}

.translating {
    filter: blur(5px);
    opacity: 0.7;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgba(255, 255, 255, 0.5); */
}

.spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
   .image-thumbnail {
        position: relative;
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .image-thumbnail img {
        width: 100px;
        height: 100px;
        object-fit: cover;
    }
    .image-checkbox-all {
        position: relative;
        top: 0px;
        left: 25px;
    }

    .image-checkbox {
        position: absolute;
        top: 0px;
        left: 25px;
    }
    .image-radio {
        position: absolute;
        top: 0px;
        left: 25px;
    }

    #editor_container {
        width: 1000px;
        height: 700px;
    }
</style>

