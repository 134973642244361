import { render, staticRenderFns } from "./imageEditor.vue?vue&type=template&id=dba52628&scoped=true"
import script from "./imageEditor.vue?vue&type=script&lang=js"
export * from "./imageEditor.vue?vue&type=script&lang=js"
import style0 from "./imageEditor.vue?vue&type=style&index=0&id=dba52628&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dba52628",
  null
  
)

export default component.exports